import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Section, SectionHeader, SectionContent, SectionColumn } from "@layouts/Section"
import * as Spacer from "@atoms/Spacer"
import Seo from "@components/atoms/Seo"
import { ExpressList } from "@projects/ExpressList"
import ListController from "@projects/ListController"
import Border from "@atoms/Border"

import { Router } from "@reach/router"
import PrivateRoute from "@projects/PrivateRoute"

//ログイン後のindexページ
//ログインしてない場合は全部indexにリダイレクト
function ExpressListPage({ data, pageContext }) {
    console.log(data, pageContext)
    const { express, emptyImg, ogpImage } = data

    // let categorysOptions = categorys.group.map(({ fieldValue }, i) => {
    //     return {
    //         value: fieldValue,
    //         label: fieldValue,
    //     }
    // })
    // categorysOptions.unshift({
    //     value: 'all',
    //     label: 'すべて',
    // })
    let yearsOptions = pageContext.years.map((val) => {
        return {
            value: val,
            label: val,
        }
    })
    yearsOptions.unshift({
        value: 'all',
        label: 'すべて',
    })
    return (
        <>
            <Seo
                subtitle="Express"
                image={ogpImage.publicURL}
            />
            <Spacer.Header />
            <Section>
                <SectionHeader
                    title="Express"
                    text="レポート"
                />
                <SectionContent>
                    <SectionColumn>
                        <ListController label="期間"
                            parent="member/express"
                            current={pageContext.year}
                            options={yearsOptions}
                            sortType="year"
                        />
                        {/* <ListController label="カテゴリ"
                            parent="news"
                            current={pageContext.category}
                            options={categorysOptions}
                            sortType="category" /> */}
                    </SectionColumn>
                    <Border />
                    <Router basepath="/member/express/">
                        <PrivateRoute path="/" component={ExpressList} data={express.edges} emptyImg={emptyImg} />
                        <PrivateRoute path="/:year" component={ExpressList} data={express.edges} emptyImg={emptyImg}/>
                    </Router>
                    {/* <ExpressList data={express.edges} emptyImg={emptyImg}/> */}
                </SectionContent>
                {/* <SectionMoreLink
                    title="More"
                    text="もっとみる"
                    to="/news/"
                /> */}
            </Section>
        </>
    )
}

export const pageQuery = graphql`
	query ExpressList(
        $periodStartDate: Date!,
        $periodEndDate: Date!
    ){
		express: allWpExpress(
			filter: {
                date: { gte: $periodStartDate, lt: $periodEndDate }
            }
			limit: 9999
			sort: { fields: [date], order: DESC }) {
			edges {
				node {
					...Express
				}
			}
		}
        emptyImg : file(relativePath: { eq: "Common__img__empty-room.png" }) { childImageSharp {
            gatsbyImageData(
                width: 1440
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
            )
        } }
        ogpImage : file(relativePath: { eq: "OGP_express.png" }) {
            publicURL
        }
	}
`
export default ExpressListPage